import React, { useState, useMemo, useEffect, ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';
import { compact, filter, get, map, debounce, find, indexOf } from 'lodash';
import { graphql, PageProps } from 'gatsby';
import { useMediaQuery } from 'react-responsive';
import { X, MapPin, Filter, ArrowRight, ArrowLeft } from 'react-feather';

import Dropdown, { OptionType } from '../../components/Dropdown';
import SchoolLocationCard from '../../components/SchoolLocationCard';
import InfiniteScroll from '../../components/InfiniteScroll';
import Input from '../../components/Input';
import SEO from '../../components/SEO/SEO';
import LayoutWrapper, { intlWrapperHOC, v4tov3HOC } from '../../components/LayoutWrapper';
import { StrapiHeroMap } from '../../components/StrapiComponents/StrapiHeroMap';
import { StrapiFooterCtaComponent } from '../../components/StrapiComponents/StrapiFooterCta';
import ComponentRenderer from '../../components/ComponentRenderer/ComponentRenderer';
import { getMapMarkersFromSchools } from '../../lib/helpers';
import { SchoolIndexPageQuery, SchoolFragment } from '../../../graphql.schema';
import { GlobalPageContext, PaginationContext } from '../../types';
import ModalFadeInCenter from '../../components/ModalFadeInCenter';
import { v4tov3base } from '../../lib/mapV4toV3';

import * as styles from './index.module.scss';

type PageContextType = GlobalPageContext & PaginationContext;
type MarkerText = {
  name: string;
  slug: any;
  address: any;
  isVirtualSchool: boolean;
};

const SchoolsIndex: React.FC<PageProps<SchoolIndexPageQuery, PageContextType>> = ({ data, pageContext }) => {
  const { websiteLocale: websiteLocaleV3, website: websiteV3 } = pageContext;
  const websiteLocale = v4tov3base({ id: websiteLocaleV3?.documentId, ...websiteLocaleV3 });
  const website = v4tov3base(websiteV3);

  const intl = useIntl();
  const schools: SchoolFragment[] = get(data, 'strapi.schools', []);
  const allSchools: SchoolFragment[] = get(data, 'strapi.schools', []);
  const schoolsIndexPage = data.strapi?.schoolsIndexPage ?? [];
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [temporaryOptions, setTemporaryOptions] = useState<string[]>([]);
  const [list, setSchoolList] = useState<SchoolFragment[]>(schools.slice(0, 10));
  const [filteredSchools, setFilteredSchools] = useState<SchoolFragment[]>([]);
  const mapComponent = find(schoolsIndexPage?.[0]?.components, ['__typename', 'Strapi_ComponentPageMap']);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showMap, setShowMap] = useState(true);
  const isDesktopOrLaptop =
    useMediaQuery({
      query: '(min-width: 1024px)',
    }) ?? true;

  const heroMapMarkers = getMapMarkersFromSchools(compact(allSchools));
  const uniqueTags = [
    ...new Set(
      map(compact(allSchools), ({ certifications }) => compact(certifications).map(({ name }) => name)).flat(),
    ),
    ...new Set(map(compact(allSchools), ({ categories }) => compact(categories).map(({ name }) => name)).flat()),
  ];

  const getFilteredSchools = useMemo(() => {
    const filterByCategory = filter(compact(schools), (school: SchoolFragment) =>
      compact(school?.categories).some((cat) => selectedTags.includes(cat.name ?? '')),
    );
    const filterByCertification = filter(compact(schools), (school: SchoolFragment) =>
      compact(school?.certifications).some((cat) => selectedTags.includes(cat.name ?? '')),
    );
    return [...filterByCategory, ...filterByCertification];
  }, [schools, selectedTags]);

  const filterBySearchTerm = (list: SchoolFragment[]) => {
    return list.filter((school: SchoolFragment) =>
      `${school.name.toLowerCase()}${school?.address?.region?.toLowerCase()}`.includes(searchTerm.toLowerCase()),
    );
  };

  const currentSchools = useMemo(() => {
    if (selectedTags.length > 0 || searchTerm.length > 0) {
      return filteredSchools;
    }
    return compact(list);
  }, [list, filteredSchools]);

  useEffect(() => {
    if (selectedTags.length) {
      setFilteredSchools(filterBySearchTerm(getFilteredSchools).slice(0, 10));
    } else {
      setFilteredSchools(filterBySearchTerm(schools).slice(0, 10));
    }
  }, [selectedTags]);

  useEffect(() => {
    setFilteredSchools(filterBySearchTerm(selectedTags.length ? getFilteredSchools : schools).slice(0, 10));
  }, [searchTerm]);

  const setSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = debounce((e) => setSearchText(e), 1000);

  if (!websiteLocale) return null;

  const getPopUpHtml = ({ name, slug, address, isVirtualSchool }: MarkerText) =>
    `<a href="${pageContext.pathPrefixes.schools}/${slug}"> 
       <div class=${classnames(styles.markerText)} >${name} 
         <span>${
           isVirtualSchool
             ? intl.formatMessage({ id: 'openVirtually' })
             : `${address?.streetAddress},${address?.postalCode}`
         }</span> 
        </div> 
    </a>`;

  const handleDropdownChange = (values: OptionType[]) => {
    const valueStrings = values.map((value) => value?.value ?? '');
    setSelectedTags([...valueStrings]);
  };

  const setOptions = (event: ChangeEvent<HTMLInputElement>) => {
    if (indexOf(temporaryOptions, event.target.value) > -1) {
      setTemporaryOptions(temporaryOptions.filter((option) => option !== event.target.value));
    } else {
      setTemporaryOptions([...temporaryOptions, event.target.value]);
    }
  };

  const clearFilter = () => setTemporaryOptions([]);
  const getMap = useMemo(
    () => (
      <StrapiHeroMap
        isSideMap
        mapTitle={''}
        markers={heroMapMarkers?.map((map) => ({
          ...map,
          popUpHtml: getPopUpHtml(map),
          school: find(schools, ['documentId', map.documentId]),
          lat: map?.mapMarker?.mapLocation?.latitude,
          lng: map?.mapMarker?.mapLocation?.longitude,
        }))}
        mapDefaultZoom={get(mapComponent, 'defaultZoom') ?? 6}
        defaultCenter={get(mapComponent, 'defaultCenter')}
        schoolSlugBase={pageContext.pathPrefixes.schools}
        selectedTags={selectedTags}
        shouldOpenLocationCard
        {...currentSchools}
      />
    ),
    [],
  );
  const { hasRightToLeftLanguage } = v4tov3base({...websiteLocale});
  return (
    <LayoutWrapper {...pageContext}>
      <SEO
        title={
          schoolsIndexPage?.[0]?.seo?.metaTitle ??
          schoolsIndexPage[0]?.pageSettings?.title ??
          intl.formatMessage({ id: 'seo.schools.title' })
        }
        description={
          schoolsIndexPage?.[0]?.seo?.metaDescription ??
          schoolsIndexPage[0]?.pageSettings?.metaDescription ??
          intl.formatMessage({ id: 'seo.schools.description' })
        }
        image={schoolsIndexPage?.[0]?.seo?.metaImage?.url}
        avoidIndexing={schoolsIndexPage?.[0]?.pageSettings?.avoidIndexing ?? false}
        canonicalURL={schoolsIndexPage?.[0]?.seo?.canonicalURL}
        keywords={schoolsIndexPage?.[0]?.seo?.keywords}
        metaSocial={schoolsIndexPage?.[0]?.seo?.metaSocial}
        structuredData={schoolsIndexPage?.[0]?.seo?.structuredData}
        currentPage={pageContext}
      />
      <div className={classnames('container', styles.main)}>
        <h1 className="title color-a mt-6 mb-3">
          <FormattedMessage id="defaultSchoolsMapTitle" defaultMessage="Our Schools" />
        </h1>
        <div className={styles.search}>
          <div className={classnames('mr-4 ml-4', styles.searchInput)}>
            <p className="control has-icons-right has-icons-left">
              <Input
                onClearSearch={() => setSearchTerm('')}
                handleSearch={handleSearch}
                placeholder={intl.formatMessage({
                  id: 'searchPlaceholder',
                  defaultMessage: 'Search for your location',
                })}
              />
            </p>
          </div>
          {isDesktopOrLaptop && (
            <div className={classnames(styles.customDropdown)}>
              <Dropdown
                closeMenuOnSelect={false}
                placeholder={intl.formatMessage({ id: 'filter', defaultMessage: 'Filter' })}
                options={compact(uniqueTags).map((name) => ({ label: name, value: name }))}
                onChange={(values) => handleDropdownChange(values)}
              />
            </div>
          )}
        </div>
        {!isDesktopOrLaptop && (
          <div className={styles.buttonFilters}>
            <button
              onClick={() => {
                setShowMap(true);
                setIsModalOpen(true);
              }}
              className={styles.spotButton}
            >
              <MapPin className="mr-2" size={20} />
              <span>
                <FormattedMessage id="map" defaultMessage="Map" />
              </span>
            </button>
            <button
              onClick={() => {
                setIsModalOpen(true), setShowMap(false);
              }}
              className={styles.spotButton}
            >
              <Filter className="mr-2" size={20} />
              <span>
                <FormattedMessage id="filter" defaultMessage="Filter" />
              </span>
            </button>
            {isModalOpen && showMap ? (
              <ModalFadeInCenter className="map-modal" isOpen={isModalOpen} shouldCloseOnOverlayClick={true}>
                <div className="map-modal-header">
                  <X
                    onClick={() => {
                      setShowMap(false);
                      setIsModalOpen(false);
                    }}
                    className={classnames('black-4')}
                    size={20}
                  />
                  <span>
                    <FormattedMessage id="map" defaultMessage="Map" />
                  </span>
                </div>
                <div className={classnames(styles.modalMap)}>{getMap}</div>
              </ModalFadeInCenter>
            ) : (
              <ModalFadeInCenter isOpen={isModalOpen} shouldCloseOnOverlayClick={true}>
                <div className={styles.modalDropdown}>
                  <div className={styles.filterHeader}>
                    <X
                      onClick={() => {
                        setIsModalOpen(false);
                      }}
                      className={classnames('black-4')}
                      size={20}
                    />
                    <h3>
                      <FormattedMessage id="filter" defaultMessage="Filter" />
                    </h3>
                    <button onClick={clearFilter} className={classnames(styles.clearButton)}>
                      <FormattedMessage id="clear" defaultMessage="Clear" />
                    </button>
                  </div>
                  {compact(uniqueTags).length ? (
                    <div className={classnames(styles.filterContent, 'px-4 pb-4')}>
                      <h2 className="mb-3">
                        <FormattedMessage id="certification" defaultMessage="CERTIFICATION" />
                      </h2>
                      {compact(uniqueTags).map((name, index) => {
                        return (
                          <label key={index} className={styles.checkboxContainer}>
                            <input
                              checked={indexOf(temporaryOptions, name) > -1}
                              value={name}
                              onChange={setOptions}
                              type="checkbox"
                            />
                            <span className={classnames(styles.checkmark)} />
                            <span className={classnames(styles.checkmarkTick)} />
                            <span className={classnames(styles.checkmarkText)}>{name}</span>
                          </label>
                        );
                      })}
                      <button
                        onClick={() => {
                          setSelectedTags([...temporaryOptions]);
                          setIsModalOpen(false);
                        }}
                        className={classnames(styles.ShowResult, styles.text)}
                      >
                        <FormattedMessage id="show-results" defaultMessage="Show Results" />
                        {hasRightToLeftLanguage ? <ArrowLeft className={styles.arrowRight} size={16} /> : <ArrowRight className={styles.arrowRight} size={16} />}                    
                      </button>
                    </div>
                  ) : (
                    <div className={'is-flex is-align-items-center is-justify-content-center mt-4'}>
                      <FormattedMessage id="no-option" defaultMessage="No Options" />
                    </div>
                  )}
                </div>
              </ModalFadeInCenter>
            )}
          </div>
        )}
        <div className={styles.schoolLocationMapContainer}>
          <p>
            {searchTerm ? (
              <span key="search-results">
                <FormattedMessage
                  id="countSchoolsOfCity"
                  defaultMessage="Showing {count} results for {city}"
                  values={{ count: <b>{currentSchools.length ?? 0}</b>, city: <b>{searchTerm}</b> }}
                />
              </span>
            ) : (
              <span key="all-results">
                <FormattedMessage
                  id="countSchoolsOfTotal"
                  defaultMessage="{count} schools of {total}"
                  values={{ count: <b>{currentSchools.length ?? 0}</b>, total: <b>{allSchools?.length ?? 0}</b> }}
                />
              </span>
            )}
          </p>
          {isDesktopOrLaptop && (
            <label className="switch" htmlFor="map-toggle">
              <p>
                {showMap ? (
                  <FormattedMessage id="hide-map" defaultMessage="Hide Map" />
                ) : (
                  <FormattedMessage id="show-map" defaultMessage="Show Map" />
                )}
              </p>
              <span>
                <input
                  type="checkbox"
                  id="map-toggle"
                  name="map-toggle"
                  onChange={() => setShowMap(!showMap)}
                  checked={showMap}
                />
                <span className="mapToggle"></span>
              </span>
            </label>
          )}
        </div>
        <div className={styles.wrappersContent}>
          <div className={styles.schoolsWrapper}>
            {currentSchools.map((school, i) => (
              <SchoolLocationCard
                schoolSlugBase={pageContext.pathPrefixes.schools}
                selectedTags={selectedTags}
                key={`filtered-school-location-card-${school.documentId}-${i}`}
                mapLocation={school?.mapMarker?.mapLocation}
                {...school}
              />
            ))}
            <InfiniteScroll
              selectedCategories={selectedTags}
              searchText={searchTerm}
              categoryList={selectedTags.length ? filterBySearchTerm(getFilteredSchools) : filterBySearchTerm(schools)}
              filteredList={currentSchools}
              completeList={schools}
              list={currentSchools}
              setList={(list) => setSchoolList(list)}
              setFilteredList={(filteredList) => setFilteredSchools(filteredList)}
            />
          </div>
          {isDesktopOrLaptop && showMap && getMap}
        </div>
      </div>
      <div className={classnames('page-components')}>
        {schoolsIndexPage &&
          schoolsIndexPage?.[0]?.components.map((component, idx) =>
            component && component.__typename !== 'Strapi_ComponentPageMap' ? (
              <ComponentRenderer
                hasRTL={websiteLocale?.hasRightToLeftLanguage}
                component={component}
                schoolSlugBase={pageContext?.pathPrefixes?.schools ?? ''}
                key={`school-homepage-component-${component.__typename}-${idx}`}
              />
            ) : null,
          )}
      </div>
      {get(websiteLocale, 'footerCta', null) && <StrapiFooterCtaComponent {...get(websiteLocale, 'footerCta', null)} />}
    </LayoutWrapper>
  );
};

export default intlWrapperHOC(v4tov3HOC(SchoolsIndex));

export const query = graphql`
  fragment SchoolCard on Strapi_School {
    slug
    name
    school_status
    schoolHero: school_hero {
      heading
      description
    }
    address {
      streetAddress: street_address
      locality
      region
      postalCode: postal_code
      country
    }
    phoneNumber: phone_number
    emailAddress: email_address
    mapMarker: map_marker {
      id
      mapLocation
    }
    certifications {
      data {
        documentId
        attributes {
          name
        }
      }
    }
    categories {
      data {
        documentId
        attributes {
          name
        }
      }
    }
  }

  query SchoolIndexPage($websiteLocaleId: ID!, $locale: Strapi_I18NLocaleCode) {
    strapi {
      schoolsIndexPage: pages(
        filters: { and: [{ website_locale: { documentId: { eq: $websiteLocaleId } } }, { index_page: { eq: "Schools" } }] }
        locale: $locale
      ) {
        data {
          documentId
          attributes {
            title
            slug
            components {
              ...PageComponents
            }
            pageSettings: page_settings {
              ...PageSettings
            }
            seo {
              ...SEOComponent
            }
          }
        }
      }
      allSchools: schools(
        filters: { website_locale: { documentId: { eq: $websiteLocaleId } } }
        sort: "name:asc"
        locale: $locale
        pagination: { limit: 300 }
      ) {
        data {
          documentId
          attributes {
            localizations {
              data {
                attributes {
                  locale
                }
              }
            }
            ...SchoolCard
          }
        }
      }
      schools(
        filters: { website_locale: { documentId: { eq: $websiteLocaleId } } }
        sort: "name:asc"
        locale: $locale
        pagination: { limit: 300 }
      ) {
        data {
          documentId
          attributes {
            localizations {
              data {
                attributes {
                  locale
                }
              }
            }
            ...SchoolCard
          }
        }
      }
    }
  }
`;
